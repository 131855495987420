import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { EventEmitter, Injectable, Output } from "@angular/core";
import { WebApiService } from "src/app/shared/services/web-api.service";
import { Page } from "src/app/shared/models/page";
import { Observable, from } from "rxjs";
import { PagedData, PagedDataUserMenu } from "src/app/shared/models/paged-data";
import { HttpErrorResponse, HttpParams } from "@angular/common/http";
import { menuPageParams, PageParams } from "src/app/shared/common/Enums";
import { map } from "rxjs/operators";
import { ApiUrlConstants } from "src/app/shared/common/ApiUrlConstants";
import { CookieService } from "ngx-cookie-service";
import { Router } from "@angular/router";
import * as moment from "moment";
import { FullLoaderService } from "src/app/shared/services/full-loader.service";
import { AddUserResponse, ChangeUserPasswordForm, ForgotPassForm, ForgotPasswordResponse, LoginForm, LoginResponse, NewPassAfterForgotPassForm, ResetPassForm, SignUpForm } from 'src/app/shared/models/user.model';
import { UserCategory } from 'src/app/shared/models/user-category.model';
import { UserMenu } from 'src/app/shared/models/user-menu.model';
import { UserMenuPage } from 'src/app/shared/models/user-menu-page';
import { AccountDataType, Category, Currency, AutoCompleteDataType, aboutDataType, supplierBranchData, supplierCatalogueProduct, supplierPortData, informationType, Brand, ImpaDataType } from 'src/app/shared/models/settings.model';

export interface UserCookieObj {
  name: string;
  email: string;
}

@Injectable({
  providedIn: "root",
})
export class SettingsService {
  constructor(
    private webApiService: WebApiService,
    private cookieService: CookieService,
    private router: Router,
    private fullLoaderService: FullLoaderService,
    private navigationService: NavigationService,
    private snack: MatSnackBar) { }

    getGeolocation(geoAddress: string):Promise<any>{
      const address = { geoAddress };
      return this.webApiService.postRequest<any>(ApiUrlConstants.getGeoLocation,address)
    }

  getAccountData():Promise<AccountDataType>{
    return this.webApiService.getRequest<AccountDataType>(ApiUrlConstants.getAccountData)
  }

  getAccountDataById(supplierId:number):Promise<AccountDataType>{
    const url = `${ApiUrlConstants.getAccountData}/${supplierId}`;
    return this.webApiService.getRequest<AccountDataType>(url)
  }

  getAboutData():Promise<aboutDataType>{
    return this.webApiService.getRequest<aboutDataType>(ApiUrlConstants.getAboutData)
  }
  // to fetch about data of specific supplier
  getAboutDataById(supplierId:number):Promise<aboutDataType>{
    const url = `${ApiUrlConstants.getAboutData}/${supplierId}`;
    return this.webApiService.getRequest<aboutDataType>(url);
  }

  getSupplierPorts(_params:any):Promise<supplierPortData>{
    return this.webApiService.postRequest<supplierPortData>(ApiUrlConstants.getSupplierPortData,_params)
  }

  getSupplierPortsById(_params:any):Promise<supplierPortData>{
    return this.webApiService.postRequest<supplierPortData>(ApiUrlConstants.getSupplierPortDataById,_params)
  }

  getSupplierBranches():Promise<supplierBranchData[]>{
    return this.webApiService.getRequest<supplierBranchData[]>(ApiUrlConstants.getSupplierBranchData)
  }

  getSupplierBranchesById(supplierId:number):Promise<supplierBranchData[]>{
    const url = `${ApiUrlConstants.getSupplierBranchData}/${supplierId}`;
    return this.webApiService.getRequest<supplierBranchData[]>(url)
  }

  getAllCatalogues():Promise<supplierCatalogueProduct[]>{
    return this.webApiService.getRequest<supplierCatalogueProduct[]>(ApiUrlConstants.supplierCatalogue)
  }

  getAllCataloguesById(supplierId:number):Promise<supplierCatalogueProduct[]>{
    const url = `${ApiUrlConstants.supplierCatalogue}/${supplierId}`;
    return this.webApiService.getRequest<supplierCatalogueProduct[]>(url);
  }

  getAllProducts(searchTerm: string,pageMetaData: Page, isImpa?: boolean, category?:boolean):Observable<PagedData<any>>{
    let _pageMetadata = new Page();
    if (pageMetaData) {
      _pageMetadata = pageMetaData;
    } else {
      _pageMetadata.pageNumber = 0;
      _pageMetadata.size = 10;
    }

    let params = new HttpParams()
      .set("category", category || false)
      .set(PageParams.limit, _pageMetadata.size)
      .set(PageParams.page, _pageMetadata.pageNumber + 1)
      .set(PageParams.searchParam, searchTerm)
      .set(PageParams.isImpa, isImpa || false)

      return this.webApiService.getPaginatedRequest<PagedData<any>>(ApiUrlConstants.products, params)
      .pipe(
        map((paginatedMaster) => {
          // Set page metadata for frontend table properties
          paginatedMaster.page.totalElements = paginatedMaster.count;
          paginatedMaster.page.size = _pageMetadata.size;
          paginatedMaster.page.totalPages = Math.ceil(
            paginatedMaster.count / _pageMetadata.size
          );
          paginatedMaster.page.pageNumber = _pageMetadata.pageNumber;
          paginatedMaster.page.serachParam = _pageMetadata.serachParam;
          console.log('total pages for search==>',paginatedMaster.page.totalPages)
          return paginatedMaster;
        }))
  }

  getBulkAttachmentUrls(body:any):Promise<any>{
    // Create a deep copy of the request body to avoid modifying the original
    const reqBody = JSON.parse(JSON.stringify(body));
    // remove the data url to reduce the size of the request body
    reqBody.map((attachment:any)=>{
      delete attachment.file
      delete attachment.dataUrl
    })
    return this.webApiService.postRequest<any>(ApiUrlConstants.getBulkProductAttachmentUrls,reqBody)
  }

  addProduct(reqBody:any):Promise<any>{
    return this.webApiService.postRequest<any>(ApiUrlConstants.createProduct,reqBody)
  }

  deleteProduct(productId:number):Promise<any>{
    let apiUrl = `${ApiUrlConstants.products}/${productId}`
    return this.webApiService.deleteRequest<any>(apiUrl)
  }

  deleteSupplierProduct(productId:number):Promise<any>{
    let apiUrl = `${ApiUrlConstants.supplierProduct}/${productId}`
    return this.webApiService.deleteRequest<any>(apiUrl)
  }

  deleteSupplierService(productId:number):Promise<any>{
    let apiUrl = `${ApiUrlConstants.supplierService}/${productId}`
    return this.webApiService.deleteRequest<any>(apiUrl)
  }

  assignProduct(reqBody:any):Promise<any>{
    return this.webApiService.postRequest<any>(ApiUrlConstants.assignProduct,reqBody)
  }

  assignService(reqBody:any):Promise<any>{
    return this.webApiService.postRequest<any>(ApiUrlConstants.assignService,reqBody)
  }

  addCategory(reqBody:any):Promise<any>{
    return this.webApiService.postRequest<any>(ApiUrlConstants.createCategory,reqBody)
  }

  addBrand(reqBody:any):Promise<any>{
    return this.webApiService.postRequest<any>(ApiUrlConstants.createBrand,reqBody)
  }

  updateProduct(reqBody:any,id:any):Promise<any>{
    let apiUrl = `${ApiUrlConstants.products}/${id}`
    return this.webApiService.putRequest<any>(apiUrl,reqBody)
  }

  deleteAttachment(attachmentArr: number[]):Promise<any>{
    const attachments = { attachmentArr };
    return this.webApiService.postRequest<any>(ApiUrlConstants.productAttachment,attachments )
  }

  deleteServiceAttachment(attachmentArr: number[]):Promise<any>{
    const attachments = { attachmentArr };
    return this.webApiService.postRequest<any>(ApiUrlConstants.ServiceAttachment,attachments )
  }
  
  
  getProductsForSupplier(id:number):Promise<any>{
    let apiUrl = `${ApiUrlConstants.productsBySupplier}+/${id}`
    return this.webApiService.getRequest<any>(apiUrl)
  }

  // getSupplierData():Promise<any>{
  //   let apiUrl = `${ApiUrlConstants.getSupplierData}`
  //   return this.webApiService.getRequest<any>(apiUrl)
  // }

  addCatalogueProduct(reqBody:any):Promise<any>{
    return this.webApiService.postRequest<any>(ApiUrlConstants.catalogueProductOp,reqBody)
  }

  updateCatalogueProduct(reqBody:any,id:any):Promise<any>{
    let apiUrl = `${ApiUrlConstants.catalogueProductOp}/${id}`
    return this.webApiService.putRequest<any>(apiUrl,reqBody)
  }

  updateSupplierBranch(reqBody:any,id:any):Promise<any>{
    let apiUrl = `${ApiUrlConstants.supplierBranchOp}/${id}`
    return this.webApiService.putRequest<any>(apiUrl,reqBody)
  }

  createSupplierBranch(reqBody:any):Promise<any>{
    return this.webApiService.postRequest<any>(ApiUrlConstants.supplierBranchOp,reqBody)
  }

  getSustainabilityData(supplierId:number):Promise<any>{
    let params = new HttpParams()
      .set('supplierId', supplierId)
    return this.webApiService.getRequest<any>(ApiUrlConstants.sustainabilityOp,params)
  }

  addOrganizationalPurpose(reqBody:any):Promise<any>{
    return this.webApiService.postRequest<any>(ApiUrlConstants.organizationalPurposeOp,reqBody)
  }

  updateOrganizationalPurpose(reqBody:any,supplierId:any):Promise<any>{
    let apiUrl = `${ApiUrlConstants.organizationalPurposeOp}/${supplierId}`
    return this.webApiService.putRequest<any>(apiUrl,reqBody)
  }

  deleteOrganizationalPurpose(supplierId:any):Promise<any>{
    let apiUrl = `${ApiUrlConstants.organizationalPurposeOp}/${supplierId}`
    return this.webApiService.deleteRequest<any>(apiUrl)
  }
  
  uploadISOCertificateData(data:any):Promise<any>{
    let apiUrl = `${ApiUrlConstants.isoCertificationOp}`
    return this.webApiService.postRequest<any>(apiUrl,data)
  }

  updateISOCertificateData(data:any,id:number):Promise<any>{
    let apiUrl = `${ApiUrlConstants.isoCertificationOp}/${id}`
    return this.webApiService.putRequest<any>(apiUrl,data)
  }

  deleteISOCertificateData(supplierId:number):Promise<any>{
    let apiUrl = `${ApiUrlConstants.isoCertificationOp}/${supplierId}`
    return this.webApiService.deleteRequest<any>(apiUrl)
  }

  addSustainabilityCommitment(reqBody:any):Promise<any>{
    return this.webApiService.postRequest<any>(ApiUrlConstants.sustainabilityCommitmentOp,reqBody)
  }

  updateSustainabilityCommitment(reqBody:any,id:any):Promise<any>{ 
    let apiUrl = `${ApiUrlConstants.sustainabilityCommitmentOp}/${id}`
    return this.webApiService.putRequest<any>(apiUrl,reqBody)
  }

  deleteSustainabilityCommitment(supplierId:any,commitmentId:number):Promise<any>{
    let params = new HttpParams()
      .set("commitmentId", commitmentId);
    let apiUrl = `${ApiUrlConstants.sustainabilityCommitmentOp}/${supplierId}`
    return this.webApiService.deleteRequest<any>(apiUrl,params)
  }
  
  uploadUNGCParticipationData(data:any):Promise<any>{
    let apiUrl = `${ApiUrlConstants.ungcParticipationOp}`
    return this.webApiService.postRequest<any>(apiUrl,data)
  }

  updateUNGCParticipationData(data:any,id:number):Promise<any>{
    let apiUrl = `${ApiUrlConstants.ungcParticipationOp}/${id}`
    return this.webApiService.putRequest<any>(apiUrl,data)
  }

  deleteUNGCParticipationData(supplierId:number):Promise<any>{
    let apiUrl = `${ApiUrlConstants.ungcParticipationOp}/${supplierId}`
    return this.webApiService.deleteRequest<any>(apiUrl)
  }

  uploadAdditionalDocumentsData(data:any):Promise<any>{
    let apiUrl = `${ApiUrlConstants.additionalDocumentsOp}`
    return this.webApiService.postRequest<any>(apiUrl,data)
  }

  updateAdditionalDocumentsData(data:any,id:number):Promise<any>{
    let apiUrl = `${ApiUrlConstants.additionalDocumentsOp}/${id}`
    return this.webApiService.putRequest<any>(apiUrl,data)
  }

  deleteAdditionalDocumentsData(supplierId:number):Promise<any>{
    let apiUrl = `${ApiUrlConstants.additionalDocumentsOp}/${supplierId}`
    return this.webApiService.deleteRequest<any>(apiUrl)
  }

  addRelevantLinks(reqBody:any):Promise<any>{
    return this.webApiService.postRequest<any>(ApiUrlConstants.relevantLinksOp,reqBody)
  }

  updateRelevantLinks(reqBody:any,id:any):Promise<any>{ 
    let apiUrl = `${ApiUrlConstants.relevantLinksOp}/${id}`
    return this.webApiService.putRequest<any>(apiUrl,reqBody)
  }

  deleteRelevantLinks(supplierId:any):Promise<any>{
    let apiUrl = `${ApiUrlConstants.relevantLinksOp}/${supplierId}`
    return this.webApiService.deleteRequest<any>(apiUrl)
  }
 
  async saveCompanyLogoUrl(_params:any,companyId:number){
    let apiUrl = `/users/uploadCompanyLogo/${companyId}`;
    return this.webApiService.postRequest(apiUrl, _params)
  }

  async updateCompanyLogoUrl(_params:any,logoId:number){
    let apiUrl = `/users/updateCompanyLogo/${logoId}`;
    return this.webApiService.putRequest(apiUrl, _params)
  }

  async saveCompanyProfileLogoUrl(_params:any,companyId:number){
    let apiUrl = `/users/uploadCompanyProfileLogo/${companyId}`;
    return this.webApiService.postRequest(apiUrl, _params)
  }

  async updateCompanyProfileLogoUrl(_params:any,logoId:number){
    let apiUrl = `/users/updateCompanyProfileLogo/${logoId}`;
    return this.webApiService.putRequest(apiUrl, _params)
  }

  async saveSupplierSummaryVideo(_params:any){
    let apiUrl = "/users/uploadSummaryVideo";
    return this.webApiService.postRequest(apiUrl, _params)
  }

  async updateSupplierSummaryVideo(_params:any,videoId:number){
    let apiUrl = `/users/updateSummaryVideo/${videoId}`;
    return this.webApiService.putRequest(apiUrl, _params)
  }

  async updateUserData(_params:any,userId:number){
    let apiUrl = `/users/update/${userId}`;
    return this.webApiService.putRequest(apiUrl, _params)
  }

  async updateCompanyAddress(_params:any,companyId:number){
    let apiUrl = `/users/updateCompany/${companyId}`;
    return this.webApiService.putRequest(apiUrl, _params)
  }

  async updateCompanyName(_params:any,companyId:number){
    let apiUrl = `/users/updateCompanyName/${companyId}`;
    return this.webApiService.putRequest(apiUrl, _params)
  }

  async uploadMembershipData(_params:any){
    let apiUrl = `/users/memberships`
    return this.webApiService.postRequest(apiUrl,_params)
  }

  async uploadCertificateData(_params:any){
    let apiUrl = `/users/certificates`
    return this.webApiService.postRequest(apiUrl,_params)
  }

  async deleteCertificate(certificateId:any){
    let apiUrl = `/company/certificate/${certificateId}`
    return this.webApiService.deleteRequest(apiUrl)
  }

  async uploadMembershipDataById(_params:any,companyId:number){
    let apiUrl = `/users/memberships/${companyId}`
    return this.webApiService.postRequest(apiUrl,_params)
  }

  async uploadCertificateDataById(_params:any,companyId:number){
    let apiUrl = `/users/certificates/${companyId}`
    return this.webApiService.postRequest(apiUrl,_params)
  }
  

  async deleteMembership(membershipId:any){
    let apiUrl = `/company/membership/${membershipId}`
    return this.webApiService.deleteRequest(apiUrl)
  }

  async createPorts(array:any[]){
    let apiUrl = `/port/bulkAdd`
      // return false;
    return this.webApiService.postRequest(apiUrl, array)
  }

  async deletePort(portId:any){
    let apiUrl = `/port/delete/${portId}`
    return this.webApiService.deleteRequest(apiUrl)
  }

  getCertificatesList():Promise<informationType[]>{
    return this.webApiService.getRequest<informationType[]>(ApiUrlConstants.getCertificates)
  }

  getCertificatesListbyId(companyId:number):Promise<informationType[]>{
    const url = `${ApiUrlConstants.getCertificates}/${companyId}`;
    return this.webApiService.getRequest<informationType[]>(url);
  }

  getMembershipList():Promise<informationType[]>{
    return this.webApiService.getRequest<informationType[]>(ApiUrlConstants.getMemberships)
  }

  getMembershipListbyId(companyId:number):Promise<informationType[]>{
    const url = `${ApiUrlConstants.getMemberships}/${companyId}`;
    return this.webApiService.getRequest<informationType[]>(url);
  }

  getSearchKeywordProducts(searchKeyword: any,supplierId?: number, pageMetaData?: Page): Observable<PagedData<supplierCatalogueProduct>> {
    let _pageMetadata = new Page();
    if (pageMetaData) {
      _pageMetadata = pageMetaData;
    } else {
      _pageMetadata.pageNumber = 0;
      _pageMetadata.size = 10;
    }

    let params = new HttpParams()
      .set(PageParams.limit, _pageMetadata.size)
      .set(PageParams.page, _pageMetadata.pageNumber + 1)
      .set("filterValue", searchKeyword)
      .set("supplierId", supplierId || 0);

    return this.webApiService.getPaginatedRequest<PagedData<supplierCatalogueProduct>>(ApiUrlConstants.catalogueProductOp,params).pipe(map(paginedProducts=>{
      // Set page metadata for frontend table properties
      paginedProducts.page.totalElements = paginedProducts.count;
      paginedProducts.page.size = _pageMetadata.size;
      paginedProducts.page.totalPages = Math.floor(
        paginedProducts.count / _pageMetadata.size
      );
      paginedProducts.page.pageNumber = _pageMetadata.pageNumber;
      paginedProducts.page.serachParam = _pageMetadata.serachParam;

      return paginedProducts;
    }))
    // console.log(searchKeyword);
    // let apiUrl = `/settings/catalogue/products/${searchKeyword}`;
    // return this.webApiService.getRequest<any>(apiUrl);
  }

  getAllCurrenciesByFliterKeyValue(_filterValue: any,pageMetaData?: Page):Observable<PagedData<Currency>>{
    let _pageMetadata = new Page();
    if (pageMetaData) {
      _pageMetadata = pageMetaData;
    } else {
      _pageMetadata.pageNumber = 0;
      _pageMetadata.size = 10;
    }
    /**
     * Set request params for pagination variables.
     * @pageNumber Add +1 in PageNumber as page start with 0 in frontend data-table.
     */
    let params = new HttpParams()
      .set(PageParams.limit, _pageMetadata.size)
      .set(PageParams.page, _pageMetadata.pageNumber + 1)
      .set("filterValue", _filterValue);

    return this.webApiService.getPaginatedRequest<PagedData<Currency>>(ApiUrlConstants.getAllCurrenciesByFliterKeyValue,params).pipe(map(paginedCurrency=>{
      // Set page metadata for frontend table properties
      paginedCurrency.page.totalElements = paginedCurrency.count;
      paginedCurrency.page.size = _pageMetadata.size;
      paginedCurrency.page.totalPages = Math.floor(
        paginedCurrency.count / _pageMetadata.size
      );
      paginedCurrency.page.pageNumber = _pageMetadata.pageNumber;
      paginedCurrency.page.serachParam = _pageMetadata.serachParam;

      return paginedCurrency;
    }))
  }

  getAllCategoriesByFliterKeyValue(_filterValue: any,pageMetaData?: Page, impa?:boolean):Observable<PagedData<Category>>{
    let _pageMetadata = new Page();
    if (pageMetaData) {
      _pageMetadata = pageMetaData;
    } else {
      _pageMetadata.pageNumber = 0;
      _pageMetadata.size = 10;
    }
    /**
     * Set request params for pagination variables.
     * @pageNumber Add +1 in PageNumber as page start with 0 in frontend data-table.
     */
    let params = new HttpParams()
      .set(PageParams.limit, _pageMetadata.size)
      .set(PageParams.page, _pageMetadata.pageNumber + 1)
      .set("filterValue", _filterValue)
      .set('isImpa',impa||false);

    return this.webApiService.getPaginatedRequest<PagedData<Category>>(ApiUrlConstants.getAllCategoriesByFliterKeyValue,params).pipe(map(paginedCategory=>{
      // Set page metadata for frontend table properties
      paginedCategory.page.totalElements = paginedCategory.count;
      paginedCategory.page.size = _pageMetadata.size;
      paginedCategory.page.totalPages = Math.floor(
        paginedCategory.count / _pageMetadata.size
      );
      paginedCategory.page.pageNumber = _pageMetadata.pageNumber;
      paginedCategory.page.serachParam = _pageMetadata.serachParam;

      return paginedCategory;
    }))
  }

  getAllServiceCategoriesByFliterKeyValue(_filterValue: any,pageMetaData?: Page, impa?:boolean):Observable<PagedData<Category>>{
    let _pageMetadata = new Page();
    if (pageMetaData) {
      _pageMetadata = pageMetaData;
    } else {
      _pageMetadata.pageNumber = 0;
      _pageMetadata.size = 10;
    }
    /**
     * Set request params for pagination variables.
     * @pageNumber Add +1 in PageNumber as page start with 0 in frontend data-table.
     */
    let params = new HttpParams()
      .set(PageParams.limit, _pageMetadata.size)
      .set(PageParams.page, _pageMetadata.pageNumber + 1)
      .set("filterValue", _filterValue)
      .set('isImpa',impa||false);

    return this.webApiService.getPaginatedRequest<PagedData<Category>>(ApiUrlConstants.getAllServiceCategoriesByFliterKeyValue,params).pipe(map(paginedCategory=>{
      // Set page metadata for frontend table properties
      paginedCategory.page.totalElements = paginedCategory.count;
      paginedCategory.page.size = _pageMetadata.size;
      paginedCategory.page.totalPages = Math.floor(
        paginedCategory.count / _pageMetadata.size
      );
      paginedCategory.page.pageNumber = _pageMetadata.pageNumber;
      paginedCategory.page.serachParam = _pageMetadata.serachParam;

      return paginedCategory;
    }))
  }

  getAllBrandsByFliterKeyValue(_filterValue: any,pageMetaData?: Page):Observable<PagedData<Brand>>{
    let _pageMetadata = new Page();
    if (pageMetaData) {
      _pageMetadata = pageMetaData;
    } else {
      _pageMetadata.pageNumber = 0;
      _pageMetadata.size = 10;
    }
    /**
     * Set request params for pagination variables.
     * @pageNumber Add +1 in PageNumber as page start with 0 in frontend data-table.
     */
    let params = new HttpParams()
      .set(PageParams.limit, _pageMetadata.size)
      .set(PageParams.page, _pageMetadata.pageNumber + 1)
      .set("filterValue", _filterValue);

    return this.webApiService.getPaginatedRequest<PagedData<Category>>(ApiUrlConstants.getAllBrandsByFliterKeyValue,params).pipe(map(paginedBrand=>{
      // Set page metadata for frontend table properties
      paginedBrand.page.totalElements = paginedBrand.count;
      paginedBrand.page.size = _pageMetadata.size;
      paginedBrand.page.totalPages = Math.floor(
        paginedBrand.count / _pageMetadata.size
      );
      paginedBrand.page.pageNumber = _pageMetadata.pageNumber;
      paginedBrand.page.serachParam = _pageMetadata.serachParam;

      return paginedBrand;
    }))
  }

  
  getAllCountriesByFliterKeyValue():Observable<PagedData<AutoCompleteDataType>>{
    let params = new HttpParams()
      // .set(PageParams.limit, _pageMetadata.size)
      // .set(PageParams.page, _pageMetadata.pageNumber + 1)
      // .set("cou", _filterValue)

    return this.webApiService.getPaginatedRequest<PagedData<AutoCompleteDataType>>(ApiUrlConstants.getAllCountriesByFilterKeyValue,params).pipe(map(paginatedCountries=>{

      return paginatedCountries;
    }))
  }

  getAllRegionsByFliterKeyValue(_countryCode: string):Observable<PagedData<AutoCompleteDataType>>{
    let params = new HttpParams()
      .set('countryCode',_countryCode)

    return this.webApiService.getPaginatedRequest<PagedData<AutoCompleteDataType>>(ApiUrlConstants.getAllRegionsByFilterKeyValue,params).pipe(map(paginedRegions=>{

      return paginedRegions;
    }))
  }

  getAllCitiesByFliterKeyValue(_stateCode:string,_countryCode: string,):Observable<PagedData<AutoCompleteDataType>>{
    let params = new HttpParams()
      .set('countryCode',_countryCode)
      .set('stateCode',_stateCode)

    return this.webApiService.getPaginatedRequest<PagedData<AutoCompleteDataType>>(ApiUrlConstants.getAllCitiesByFilterKeyValue,params).pipe(map(paginedCities=>{

      return paginedCities;
    }))
  }

  getAllMembershipsByFliterKeyValue(_filterValue: any,pageMetaData?: Page):Observable<PagedData<AutoCompleteDataType>>{
    let _pageMetadata = new Page();
    if (pageMetaData) {
      _pageMetadata = pageMetaData;
    } else {
      _pageMetadata.pageNumber = 0;
      _pageMetadata.size = 10;
    }
    /**
     * Set request params for pagination variables.
     * @pageNumber Add +1 in PageNumber as page start with 0 in frontend data-table.
     */
    let params = new HttpParams()
      .set(PageParams.limit, _pageMetadata.size)
      .set(PageParams.page, _pageMetadata.pageNumber + 1)
      .set("filterValue", _filterValue)

    return this.webApiService.getPaginatedRequest<PagedData<AutoCompleteDataType>>(ApiUrlConstants.getAllMembershipsByFliterKeyValue,params).pipe(map(paginatedMemberships=>{
      // Set page metadata for frontend table properties
      paginatedMemberships.page.totalElements = paginatedMemberships.count;
      paginatedMemberships.page.size = _pageMetadata.size;
      paginatedMemberships.page.totalPages = Math.floor(
        paginatedMemberships.count / _pageMetadata.size
      );
      paginatedMemberships.page.pageNumber = _pageMetadata.pageNumber;
      paginatedMemberships.page.serachParam = _pageMetadata.serachParam;

      return paginatedMemberships;
    }))
  }

  getAllCertificatesByFliterKeyValue(_filterValue: any,pageMetaData?: Page):Observable<PagedData<AutoCompleteDataType>>{
    let _pageMetadata = new Page();
    if (pageMetaData) {
      _pageMetadata = pageMetaData;
    } else {
      _pageMetadata.pageNumber = 0;
      _pageMetadata.size = 10;
    }
    /**
     * Set request params for pagination variables.
     * @pageNumber Add +1 in PageNumber as page start with 0 in frontend data-table.
     */
    let params = new HttpParams()
      .set(PageParams.limit, _pageMetadata.size)
      .set(PageParams.page, _pageMetadata.pageNumber + 1)
      .set("filterValue", _filterValue)

    return this.webApiService.getPaginatedRequest<PagedData<AutoCompleteDataType>>(ApiUrlConstants.getAllCertificatesByFliterKeyValue,params).pipe(map(paginatedCertificates=>{
      // Set page metadata for frontend table properties
      paginatedCertificates.page.totalElements = paginatedCertificates.count;
      paginatedCertificates.page.size = _pageMetadata.size;
      paginatedCertificates.page.totalPages = Math.floor(
        paginatedCertificates.count / _pageMetadata.size
      );
      paginatedCertificates.page.pageNumber = _pageMetadata.pageNumber;
      paginatedCertificates.page.serachParam = _pageMetadata.serachParam;

      return paginatedCertificates;
    }))
  }

  getAllPortsByFliterKeyValue(_filterValue: any,pageMetaData?: Page):Observable<PagedData<AutoCompleteDataType>>{
    let _pageMetadata = new Page();
    if (pageMetaData) {
      _pageMetadata = pageMetaData;
    } else {
      _pageMetadata.pageNumber = 0;
      _pageMetadata.size = 10;
    }
    /**
     * Set request params for pagination variables.
     * @pageNumber Add +1 in PageNumber as page start with 0 in frontend data-table.
     */
    let params = new HttpParams()
      .set(PageParams.limit, _pageMetadata.size)
      .set(PageParams.page, _pageMetadata.pageNumber + 1)
      .set("filterValue", _filterValue)

    return this.webApiService.getPaginatedRequest<PagedData<AutoCompleteDataType>>(ApiUrlConstants.getAllPortsByFliterKeyValue,params).pipe(map(paginatedPorts=>{
      // Set page metadata for frontend table properties
      paginatedPorts.page.totalElements = paginatedPorts.count;
      paginatedPorts.page.size = _pageMetadata.size;
      paginatedPorts.page.totalPages = Math.floor(
        paginatedPorts.count / _pageMetadata.size
      );
      paginatedPorts.page.pageNumber = _pageMetadata.pageNumber;
      paginatedPorts.page.serachParam = _pageMetadata.serachParam;

      return paginatedPorts;
    }))
  }

  getAllSDGCommitmentsByFliterKeyValue(_filterValue: any,_commitmentsDeclared: any[],pageMetaData?: Page):Observable<PagedData<AutoCompleteDataType>>{
    let _pageMetadata = new Page();
    if (pageMetaData) {
      _pageMetadata = pageMetaData;
    } else {
      _pageMetadata.pageNumber = 0;
      _pageMetadata.size = 10;
    }
    /**
     * Set request params for pagination variables.
     * @pageNumber Add +1 in PageNumber as page start with 0 in frontend data-table.
     */
    let params = new HttpParams()
      .set(PageParams.limit, _pageMetadata.size)
      .set(PageParams.page, _pageMetadata.pageNumber + 1)
      .set("filterValue", _filterValue)
      .set('_commitmentsDeclared',JSON.stringify(_commitmentsDeclared))

    return this.webApiService.getPaginatedRequest<PagedData<AutoCompleteDataType>>(ApiUrlConstants.getAllSDGCommitmentsByFliterKeyValue,params).pipe(map(paginatedCommitments=>{
      // Set page metadata for frontend table properties
      paginatedCommitments.page.totalElements = paginatedCommitments.count;
      paginatedCommitments.page.size = _pageMetadata.size;
      paginatedCommitments.page.totalPages = Math.floor(
        paginatedCommitments.count / _pageMetadata.size
      );
      paginatedCommitments.page.pageNumber = _pageMetadata.pageNumber;
      paginatedCommitments.page.serachParam = _pageMetadata.serachParam;
      console.log('returning sdg commitments==>',paginatedCommitments)
      return paginatedCommitments;
    }))
  }

  async downloadS3FileAsBlob(url: string,fileName:string='fileName.ext'): Promise<File> {
    const blob:any = await this.webApiService.getS3FileAsBlob(url)
    return new File([blob], fileName, { type: blob.type });
  }

  getImpaData():Promise<any>{
    return this.webApiService.getRequest<any>(ApiUrlConstants.getImpaData)
  }

  getImpaDataById(supplierId:any, searchTerm: string, pageMetaData: Page ):Observable<PagedData<any>>{
    
    let _pageMetadata = new Page();
    if (pageMetaData) {
      _pageMetadata = pageMetaData;
    } else {
      _pageMetadata.pageNumber = 0;
      _pageMetadata.size = 10;
    }

    let params = new HttpParams()
      .set(PageParams.limit, _pageMetadata.size)
      .set(PageParams.page, _pageMetadata.pageNumber + 1)
      .set(PageParams.searchParam, searchTerm)
      .set("supplierId", supplierId)
    return this.webApiService.getPaginatedRequest<PagedData<any>>(ApiUrlConstants.supplierImpaproduct,params)
    .pipe(
      map((paginatedMaster) => {
        // Set page metadata for frontend table properties
        paginatedMaster.page.totalElements = paginatedMaster.count;
        paginatedMaster.page.size = _pageMetadata.size;
        paginatedMaster.page.totalPages = Math.ceil(
          paginatedMaster.count / _pageMetadata.size
        );
        paginatedMaster.page.pageNumber = _pageMetadata.pageNumber;
        paginatedMaster.page.serachParam = _pageMetadata.serachParam;
        console.log('total pages for search==>',paginatedMaster.page.totalPages)
        return paginatedMaster;
      }))

  }

  // service api
  getServiceData():Promise<any>{

    return this.webApiService.getRequest<any>(ApiUrlConstants.getServiceData)
  }

  getServiceDataById(supplierId:any, searchTerm: string, pageMetaData: Page ):Observable<PagedData<any>>{
    
    let _pageMetadata = new Page();
    if (pageMetaData) {
      _pageMetadata = pageMetaData;
    } else {
      _pageMetadata.pageNumber = 0;
      _pageMetadata.size = 10;
    }

    let params = new HttpParams()
      .set(PageParams.limit, _pageMetadata.size)
      .set(PageParams.page, _pageMetadata.pageNumber + 1)
      .set(PageParams.searchParam, searchTerm)
      .set("supplierId", supplierId)
    return this.webApiService.getPaginatedRequest<PagedData<any>>(ApiUrlConstants.getSupplierServiceData,params)
    .pipe(
      map((paginatedMaster) => {
        // Set page metadata for frontend table properties
        paginatedMaster.page.totalElements = paginatedMaster.count;
        paginatedMaster.page.size = _pageMetadata.size;
        paginatedMaster.page.totalPages = Math.ceil(
          paginatedMaster.count / _pageMetadata.size
        );
        paginatedMaster.page.pageNumber = _pageMetadata.pageNumber;
        paginatedMaster.page.serachParam = _pageMetadata.serachParam;
        console.log('total pages for search==>',paginatedMaster.page.totalPages)
        return paginatedMaster;
      }))

  }
  
  getAllServiceData(searchTerm: string, pageMetaData: Page):Observable<PagedData<any>>{
    
    let _pageMetadata = new Page();
    if (pageMetaData) {
      _pageMetadata = pageMetaData;
    } else {
      _pageMetadata.pageNumber = 0;
      _pageMetadata.size = 10;
    }

    let params = new HttpParams()
      .set(PageParams.limit, _pageMetadata.size)
      .set(PageParams.page, _pageMetadata.pageNumber + 1)
      .set(PageParams.searchParam, searchTerm)
    return this.webApiService.getPaginatedRequest<PagedData<any>>(ApiUrlConstants.getAllServiceData,params)
    .pipe(
      map((paginatedMaster) => {
        // Set page metadata for frontend table properties
        paginatedMaster.page.totalElements = paginatedMaster.count;
        paginatedMaster.page.size = _pageMetadata.size;
        paginatedMaster.page.totalPages = Math.ceil(
          paginatedMaster.count / _pageMetadata.size
        );
        paginatedMaster.page.pageNumber = _pageMetadata.pageNumber;
        paginatedMaster.page.serachParam = _pageMetadata.serachParam;
        console.log('total pages for search==>',paginatedMaster.page.totalPages)
        return paginatedMaster;
      }))
}

  addService(reqBody:any):Promise<any>{
    return this.webApiService.postRequest<any>(ApiUrlConstants.createService,reqBody)
  }

  deleteService(serviceId:number):Promise<any>{
    let apiUrl = `${ApiUrlConstants.getServiceData}/${serviceId}`
    return this.webApiService.deleteRequest<any>(apiUrl)
  }

  updateService(reqBody:any,id:any):Promise<any>{
    let apiUrl = `${ApiUrlConstants.createService}/${id}`
    return this.webApiService.putRequest<any>(apiUrl,reqBody)
  }
// service api ends

  getBrandCategory(params:string,supplierId:number):Promise<any>{
    const url = `${ApiUrlConstants.getBrandCategory}/${params}/${supplierId}`;
    return this.webApiService.getRequest<any>(url)
  }

  getSearchSupplierEmail(index:number,searchParam: any,userCategory:any): Promise<any> {
    // console.log(searchKeyword);
    let apiUrl = `/search/supplierLists/validate?index=${index}&searchParam=${searchParam}&userCategory=${userCategory}`;

    return this.webApiService.getRequest<any>(apiUrl);
  }

  addCompanyProductServiceDescription(reqBody:any):Promise<any>{
    return this.webApiService.postRequest<any>(ApiUrlConstants.addCompanyProductServiceDescription,reqBody)
  }

  getProductServiceDescriptionById(companyId:number):Promise<any>{
    const url = `${ApiUrlConstants.getProductServiceDescription}/${companyId}`;
    return this.webApiService.getRequest<any>(url);
  }

  getCatalogueDataById(supplierId:any, searchTerm: string, pageMetaData: Page ):Observable<PagedData<any>>{
    
    let _pageMetadata = new Page();
    if (pageMetaData) {
      _pageMetadata = pageMetaData;
    } else {
      _pageMetadata.pageNumber = 0;
      _pageMetadata.size = 10;
    }

    let params = new HttpParams()
      .set(PageParams.limit, _pageMetadata.size)
      .set(PageParams.page, _pageMetadata.pageNumber + 1)
      .set(PageParams.searchParam, searchTerm)
      .set("supplierId", supplierId)
    return this.webApiService.getPaginatedRequest<PagedData<any>>(ApiUrlConstants.getCatalogueDataById,params)
    .pipe(
      map((paginatedMaster) => {
        // Set page metadata for frontend table properties
        paginatedMaster.page.totalElements = paginatedMaster.count;
        paginatedMaster.page.size = _pageMetadata.size;
        paginatedMaster.page.totalPages = Math.ceil(
          paginatedMaster.count / _pageMetadata.size
        );
        paginatedMaster.page.pageNumber = _pageMetadata.pageNumber;
        paginatedMaster.page.serachParam = _pageMetadata.serachParam;
        console.log('total pages for search==>',paginatedMaster.page.totalPages)
        console.log(paginatedMaster.count,_pageMetadata.size)
        return paginatedMaster;
      }))
  }

  
  addCatalogue(reqBody:any):Promise<any>{
    return this.webApiService.postRequest<any>(ApiUrlConstants.CreateCatalogue,reqBody)
  }

  deleteCatalogue(catalogueId:number):Promise<any>{
    let apiUrl = `${ApiUrlConstants.getCatalogueDataById}/${catalogueId}`
    return this.webApiService.deleteRequest<any>(apiUrl)
  }

  updateCatalogue(reqBody:any,id:any):Promise<any>{
    let apiUrl = `${ApiUrlConstants.CreateCatalogue}/${id}`
    return this.webApiService.putRequest<any>(apiUrl,reqBody)
  }

  getSearchImpaItems(searchTerm: string, itemType:string){
    let params = new HttpParams()
    .set(PageParams.searchParam, searchTerm)
    .set("itemType", itemType)
    return this.webApiService.getRequest<any>(ApiUrlConstants.searchImpaItems, params)
  }

  getSupplierProfileData(supplierId:any):Promise<any>{
    const params = { supplierId };
    return this.webApiService.postRequest<any>(ApiUrlConstants.getSupplierProfileData,params)
  }

  getLimitedProfileData(params:string,supplierId:number):Promise<any>{
    const url = `${ApiUrlConstants.getLimitedProfileData}/${params}/${supplierId}`;
    return this.webApiService.getRequest<any>(url)
  }

  getSearchCategoryItems(searchTerm: string,supplierId:any){
    let params = new HttpParams()
    .set(PageParams.searchParam, searchTerm)
    .set("supplierId", supplierId)
    return this.webApiService.getRequest<any>(ApiUrlConstants.searchCategoryItems, params)
  }

  getSearchBrandItems(searchTerm: string,supplierId:any){
    let params = new HttpParams()
    .set(PageParams.searchParam, searchTerm)
    .set("supplierId", supplierId)
    return this.webApiService.getRequest<any>(ApiUrlConstants.searchBrandItems, params)
  }

  getArchivedItems(getFor:string,supplierId:number):Promise<any>{
    let params = new HttpParams()
    .set("getFor", getFor)
    .set("supplierId", supplierId)
    return this.webApiService.getRequest<any>(ApiUrlConstants.getArchivedItems, params)
  }

  restoreArchivedItems(params:any):Promise<any>{
    return this.webApiService.postRequest<any>(ApiUrlConstants.restoreArchivedItems, params)
  }

  inviteUserBySupplier(reqBody:any):Promise<any>{
    return this.webApiService.postRequest<any>(ApiUrlConstants.inviteUserBySupplier,reqBody)
  }
  

  // fetchUserBySupplierId(supplierId:number):Promise<any>{
  //   const url = `${ApiUrlConstants.fetchUserBySupplier}/${supplierId}`;
  //   return this.webApiService.getRequest<any>(url);
  // }

  fetchUserBySupplierId(supplierId:number,limit: number, offset: number, searchParam: string = ''): Observable<{ rows: any[], totalElements: number }> {
    const apiUrl = `${ApiUrlConstants.fetchUserBySupplier}?supplierId=${supplierId}&limit=${limit}&offset=${offset}&searchParam=${encodeURIComponent(searchParam)}`;
    return from(this.webApiService.getRequest1<{  rows: any[], totalElements: number }>(apiUrl))
    
  }

}