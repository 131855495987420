import { environment } from "../../../environments/environment";

export const ApiUrlConstants = {
    getUserDetails: "/dashboard/getAdminDashboardUsersDetail",

  //#region User routes
  ResendTemporaryPassword: "/users/resendTemporaryPassword",

  currentUserLogin: "/users/login",

  resetUserPassword: "/users/resetUserPassword",

  forgotPassword: "/users/forgotPassword",

  setNewPasswordFromForgot: "/users/newPassword",
 
  GetUserDetailByToken: "/users/getDetailsByTokenId",

  LogoutCurrentUser: "/users/logout",

  getLoginUserDetails: "/users/getDetails",

  GetUserCategories: "/userCategories",

  /** Get user detail by logged user's email. */
  GetUserDetailByEmail: "/users/getDetailsByEmail",
  //#endregion

  getPaginatedUserClasses: "",

  getRegistrationFormData: "/users/getRegistrationData",
  registerUser: '/users/signup', //This route to be changed to '/users/signup' when actual connection


  // !Settings screen routes
  getAccountData: "/settings/accounts",
  getAboutData: "/settings/about",
  getSupplierPortData: "/settings/supplierPortsById",
  getSupplierPortDataById: "/settings/supplierPortsById",
  getSupplierBranchData: "/settings/supplierBranches",
  supplierCatalogue: "/settings/catalogues",
  products: "/products", //!Same can be used to fetch single catalogue by passing id after,
  supplierProduct:"/settings/supplierProduct",
  createProduct: "/products/create",
  assignProduct: "/products/assign",
  assignService: "/services/assign",
  createCategory: "/categories/create",
  createBrand: "/brands/create",
  productAttachment: "/products/attachment",
  ServiceAttachment: "/services/attachment",
  getBulkProductAttachmentUrls: "/products/generateBulkUploadUrls",
  productsBySupplier: "/settings/supplier/products",
  getAllCurrenciesByFliterKeyValue: "/settings/getAllCurrenciesByFliterValue",
  getAllCategoriesByFliterKeyValue: "/settings/getAllCategoriesByFliterValue",
  getAllServiceCategoriesByFliterKeyValue: "/settings/getAllServiceCategoryByFliterValue",
  getAllBrandsByFliterKeyValue: "/settings/getAllBrandsByFliterValue",
  getAllCountriesByFilterKeyValue:"/settings/getAllCountriesByFliterValue",
  getAllRegionsByFilterKeyValue:"/settings/getAllRegionsByFliterValue",
  getAllCitiesByFilterKeyValue:"/settings/getAllCitiesByFliterValue",
  getAllMembershipsByFliterKeyValue:'/settings/getAllMembershipsByFliterValue',
  getAllCertificatesByFliterKeyValue:'/settings/getAllCertificatesByFliterValue',
  getAllPortsByFliterKeyValue:'/settings/getAllPortsByFliterValue',
  getAllSDGCommitmentsByFliterKeyValue:'/settings/getAllSDGCommitmentsByFliterValue',
  catalogueProductOp:'/settings/catalogue/product',
  getSupplierData: "/settings/supplier/data",
  getMemberships:"/company/memberships",
  getCertificates: "/company/certificates",
  supplierBranchOp:'/supplier/branch',
  sustainabilityOp:'/settings/sustainability',
  organizationalPurposeOp:'/settings/sustainability/organizationalPurpose',
  isoCertificationOp: '/settings/sustainability/isoCertification',
  sustainabilityCommitmentOp: "/settings/sustainability/sustainableCommitments",
  ungcParticipationOp:"/settings/sustainability/ungcParticipations",
  additionalDocumentsOp:"/settings/sustainability/additionalDocuments",
  relevantLinksOp: "/settings/sustainability/relevantLinks",
  getImpaData:"/settings/impaproduct",
  supplierImpaproduct:"/settings/supplierImpaproduct",
  getBrandCategory:"/settings/brandcategory",
  getServiceData:"/services",
  getSupplierServiceData:"/services/fetchSupplierService",
  getAllServiceData:"/services/standAloneService",
  createService:"/services/create",
  supplierService:"/services/supplierService",
  addCompanyProductServiceDescription:"/company/productServiceDescription/create",
  getProductServiceDescription:"/company/productServiceDescription",

  getCatalogueDataById:"/settings/supplierCatalogue",
  CreateCatalogue:"/settings/supplierCatalogue/create",
  searchImpaItems: "/search/serchItems",
  getGeoLocation:"/settings/supplierBranch/location",

  getSupplierProfileData:"/supplier/supplierProfileData",
  getLimitedProfileData:"/supplier/supplierProfile",
  searchCategoryItems: "/search/searchCategory",
  searchBrandItems: "/search/searchBrand",
  getArchivedItems: "/settings/archivedItems",
  restoreArchivedItems: "/settings/restoreArchivedItems",

  inviteUserBySupplier: "/users/supplier/createUser",

  fetchUserBySupplier:"/users/supplier/fetchUserBySupplierId"
  
};
